import React from "react"
import { Link } from "gatsby"

import "../css/wedding.css"

export default function Wedding(props) {
  return (
    <>
      <section className="Wedding" id={props.name}>
        <h2>{props.name}</h2>
        <div>
          <dl className="Capacity">
            <dt>{props.capacity}</dt>
            <dd>Capacity</dd>
          </dl>
          <hr />
        </div>
        <div>
          <h4>
            <strong>{props.name} Wedding</strong>
          </h4>
          {props.description}
          <p>
            {props.package && (
              <a href={props.package} target="_blank" className="Button">
                View Packages
              </a>
            )}
            <Link to="/contact" className="Button">
              Get in Touch
            </Link>

            <a
              href={props.floorplan}
              target="_blank"
              className="Button Button--transparent Button--transparent-salmon"
            >
              View Floor Plan
            </a>
          </p>
        </div>
      </section>
      <div className="Wedding__gallery">{props.children}</div>
    </>
  )
}
