import React from "react"
import Layout from "../layout"

import "../css/floater.css"
import "../css/intro.css"
import "../css/features.css"
import "../css/wedding.css"

import Wedding from "../components/Wedding"
import Video from "../components/Video"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import videos from "../util/videos"

export default function Weddings() {
  // TODO: does this load the right sizes; e.g. 100% of container or 100vw of container?
  const images = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "weddings/Roadsidex2ndFlr_149@2x.jpg" }) {
        ...jpgFragment
      }

      wedding: file(
        relativePath: { eq: "weddings/kyle-mel-wedding-0474@2x.jpg" }
      ) {
        ...jpgFragment
      }

      kylemel: file(
        relativePath: { eq: "weddings/Kyle-Melanie-sneakpeek-0054.jpg" }
      ) {
        ...jpgFragment
      }

      seating: file(relativePath: { eq: "weddings/seating@2x.jpg" }) {
        ...jpgFragment
      }

      roadside: file(
        relativePath: { eq: "weddings/Roadsidex2ndFlr_015@2x.jpg" }
      ) {
        ...jpgFragment
      }

      chairs: file(relativePath: { eq: "weddings/chairs@2x.jpg" }) {
        ...jpgFragment
      }

      rectangle: file(relativePath: { eq: "weddings/Rectangle 86@2x.jpg" }) {
        ...jpgFragment
      }

      distanced: file(relativePath: { eq: "weddings/distance@2x.jpg" }) {
        ...jpgFragment
      }

      openhouse: file(
        relativePath: {
          eq: "weddings/2ndfloor-openhouse-johnandsamanthabutlerphotoandvideo-30@2x.jpg"
        }
      ) {
        ...jpgFragment
      }
    }
  `)

  return (
    <Layout>
      <header class="FloatingHeader FloatingHeader--right">
        <h1>Weddings</h1>
      </header>
      <section className="Intro Intro--wedding">
        <div>
          <div>
            <figure>
              <Img
                fluid={{
                  ...images.intro.childImageSharp.fluid,
                }}
              />
            </figure>
          </div>
        </div>
        <div>
          <div>
            <p className="Section__intro">
              Congratulations on your engagement and welcome to
              Second&nbsp;Floor&nbsp;Events. This is a 119-year old renovated
              heritage property in the heart of downtown Toronto that features
              8-foot windows, original wood floors and sleek 36-foot bar. Let’s
              plan a wedding!
            </p>
          </div>
          <figure>
            <Img fluid={images.wedding.childImageSharp.fluid} />
          </figure>
        </div>
      </section>

      <section className="Floater Floater--weddings">
        <figure>
          <Img fluid={images.kylemel.childImageSharp.fluid} />
        </figure>
        <div className="Floater__card">
          <p>
            Second&nbsp;Floor&nbsp;Events specializes in perfecting many
            services in-house, alleviating your need to source them externally.
          </p>

          <p>
            This includes everything from furniture to your unique menu (made
            and served by our in-house catering team and Executive Chef) to wait
            staff and bartenders. With the help of your dedicated Venue
            Specialist, you’ll also have the opportunity to choose between two
            distinct dining setups: Cocktail Style and Seated.
          </p>

          <hr className="vr" />

          <p className="More">Learn More:</p>

          <div className="Buttons">
            <Link
              className="Button Button--white Button--white-salmon"
              to="#Cocktail"
            >
              Cocktail
            </Link>
            <Link className="Button Button--transparent" to="#Seated">
              Seated
            </Link>
            <Link className="Button Button--transparent" to="#Micro">
              Micro
            </Link>
          </div>
        </div>

        <figure>
          <Img fluid={images.roadside.childImageSharp.fluid} />
        </figure>
      </section>

      <div class="VideoLoop">
        <Video playback_id={videos.weddings} />
      </div>

      <Wedding
        name="Cocktail"
        capacity="350"
        description={[
          <p>
            This layout of wedding allows guests to experience a larger variety
            of our menu, through tapas-sized portions of dishes served
            throughout the space after the ceremony. The choice of
            ceremony-style setup is yours, along with open bar service provided
            throughout your wedding.
          </p>,
          <p>
            If you’re interested in menu options, prices or to book a showing:
          </p>,
        ]}
      >
        <figure>
          <Img fluid={images.chairs.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.rectangle.childImageSharp.fluid} />
        </figure>
      </Wedding>
      <Wedding
        name="Seated"
        capacity="180"
        description={[
          <p>
            This layout of wedding provides tableside service at a seated
            reception set up after your ceremony (guests enjoy passed canapés on
            the East side of the venue as the rest of the space is flipped). The
            choice of ceremony-style setup is yours, along with open bar service
            provided throughout your wedding.
          </p>,
          <p>
            If you’re interested in menu options, prices or to book a showing:
          </p>,
        ]}
        floorplan="/floor-plans/2FL Floor Plan - Seated.pdf"
      >
        <figure>
          <Img fluid={images.seating.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.openhouse.childImageSharp.fluid} />
        </figure>
      </Wedding>
      <Wedding
        name="Micro"
        capacity="50"
        description={[
          <p>
            As a turnkey space, Second&nbsp;Floor&nbsp;Events is proud to
            reimagine milestone occasions in the face of today's
            current&nbsp;events.
          </p>,
          <p>
            For couples hoping to get married before 2021, customizable
            Micro-Wedding packages are now available.
          </p>,
        ]}
        floorplan="/floor-plans/2FL Floor Plan - Micro Wedding.pdf"
        package="/micro-wedding.pdf"
      >
        <figure>
          <Img fluid={images.distanced.childImageSharp.fluid} />
        </figure>
      </Wedding>
    </Layout>
  )
}
